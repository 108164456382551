import React, { useState, useEffect, useMemo } from "react";
import BigNumber from "bignumber.js";
import styles from "./Bridge.module.scss";
import {
  Button,
  Input,
  Spin,
  Select,
  Modal,
  InputNumber,
  Tabs,
  Tag,
} from "antd";
import { useTranslation } from "react-i18next";
import { SwapOutlined } from "@ant-design/icons";
import BridgeRecord from "./BridgeRecord";
import Header from "components/Header/Header";

import BscIcon from "assets/imgs/BNB-chain.png";
import EthIcon from "assets/imgs/ETH.png";
import DeeperIcon from "assets/imgs/logo-white-sm.png";
import DprIcon from "assets/imgs/dpr-coin.png";
import {
  switchBSC,
  getCurrentInfo,
  checkMetamask,
  ethToDeeperChain,
  deeperChainToEth,
  getBscBalance,
} from "w3/eth/index";
import {
  getPolkadotAccountList,
  checkPolkadotjs,
  signString,
  decodeDeeperChain,
  getDeeperChainBalance,
} from "w3/polkadot";
import { useRequest } from "ahooks";
import { useBurnRate } from "./useBurnRate";
import RuleBox from "./RuleBox";
import BridgeRanking from "./Ranking";
import BurnRecord from "./BurnRecord";

const Limit = {
  max: 10000,
  min: 1000,
  maxTotal: 2000000,
};

function Bridge() {
  const [direction, setDirection] = useState<string>(() => {
    let storeDirection = window.localStorage.getItem("direction")
      ? window.localStorage.getItem("direction")
      : "1";
    return String(storeDirection);
  });
  const [amount, setAmount] = useState(10000);
  const [network, setNetwork] = useState("BSC");
  const [chainId, setChainId] = useState(56);
  const [ethAccount, setEthAccount] = useState("");
  const [deeperAccount, setDeeperAccount] = useState("");
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const {
    burnRate,
    setBurnRate,
    changeBurnRateOpen,
    setChangeBurnRateOpen,
    runGetBurnRate,
    onSetBurnRate,
    getBurnRateLoading,
    burnRateInputRef,
  } = useBurnRate({ modal, t });
  const [minBurnRate, setMinBurnRate] = useState(0);
  const [initStatus, setInitStatus] = useState(() => {
    const metamask = checkMetamask();

    if (!metamask) {
      return 1;
    }
    return 0;
  });
  const burnAmount = useMemo(() => {
    return Number(((amount * Number(burnRate)) / 100).toFixed(2));
  }, [amount, burnRate]);
  const totalAmount = useMemo(() => {
    return burnAmount + amount;
  }, [amount, burnAmount]);

  useEffect(() => {
    if (initStatus === 1) {
      modal.confirm({
        title: "Install MetaMask",
        content: "Please install MetaMask",
        onOk: () => {
          window.open(
            "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
          );
        },
      });
      return;
    }
    if (initStatus === 2) {
      modal.confirm({
        title: "Install polkadot{.js} extension",
        content: "Please install polkadot{.js} extension",
        onOk: () => {
          window.open(
            "https://chrome.google.com/webstore/detail/polkadot%7Bjs%7D-extension/mopnmbcafieddcagagdcbnhejhlodfdd"
          );
        },
      });
      return;
    }
    runGetCurrentInfo();
  }, [initStatus]);

  const {
    data: currentInfo,
    loading: getCurrentInfoLoading,
    run: runGetCurrentInfo,
  } = useRequest(getCurrentInfo, {
    manual: true,
    onSuccess(res) {
      console.log("currentInfo");
      setEthAccount(res.account);
      setChainId(res.networkId);
      if (res.networkId === 56) {
        setNetwork("BSC");
      } else {
        switchNetwork();
      }
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: "Error",
        content: e.message,
      });
    },
  });
  const {
    data: deeperChainAccountList = [],
    loading: getDeeperChainAccountLoading,
  } = useRequest(getPolkadotAccountList, {
    onSuccess(res: any) {
      console.log(res);
      if (res[0]) {
        setDeeperAccount(res[0].address);
      } else {
        if (checkPolkadotjs()) {
          setInitStatus(2);
        }
      }
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: "Error",
        content: e.message,
      });
    },
  });

  const { run: switchNetwork, loading: switchLoading } = useRequest(
    () => {
      return switchBSC();
    },
    {
      manual: true,
      onError(e) {
        console.log(e);
        modal.error({
          title: "Error",
          content: e.message,
        });
      },
    }
  );

  useEffect(() => {
    if (ethAccount) {
      runGetBurnRate(ethAccount);
    }
  }, [ethAccount, runGetBurnRate]);

  const {
    data: deeperChainBalance = 0,
    run: runGetDeeperDprBalance,
    loading: runGetDeeperDprBalanceLoading,
  } = useRequest(
    async (deeperChain: string) => {
      const data = await getDeeperChainBalance(deeperChain);
      return data.data.free;
    },
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
      },
      onError(e) {
        console.log(e);
        modal.error({
          title: "Error",
          content: e.message,
        });
      },
    }
  );

  const {
    data: ethDprBalance = 0,
    run: runGetEthDprBalance,
    loading: runGetEthDprBalanceLoading,
  } = useRequest(getBscBalance, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: "Error",
        content: e.message,
      });
    },
  });

  useEffect(() => {
    if (
      initStatus === 0 &&
      deeperChainAccountList.length === 0 &&
      !getDeeperChainAccountLoading
    ) {
      modal.warning({
        title: "Import Deeper Chain Wallet",
        content: "Please import Deeper Chain wallet in polkadot{.js} extension",
      });
    }
  }, [initStatus, deeperChainAccountList, getDeeperChainAccountLoading]);

  useEffect(() => {
    if (deeperAccount) {
      runGetDeeperDprBalance(deeperAccount);
    }
  }, [deeperAccount]);

  useEffect(() => {
    if (ethAccount && chainId === 56) {
      runGetEthDprBalance(ethAccount);
    }
  }, [ethAccount, chainId]);

  const isInsufficientBalance = useMemo(() => {
    if (direction === "1") {
      return totalAmount > deeperChainBalance / 1e18;
    }
    if (direction === "2") {
      return amount > ethDprBalance / 1e18;
    }
  }, [totalAmount, deeperChainBalance, direction]);

  const ETHComp = () => (
    <div>
      {network === "BSC" && (
        <div className={styles["network-info"]}>
          <div className={styles["logo-box"]}>
            <img src={BscIcon} alt="" />
          </div>

          <div className={styles["network-label"]}>Smart Chain</div>
        </div>
      )}
      {network === "ETH" && (
        <div className={styles["network-info"]}>
          <div className={styles["logo-box"]}>
            <img src={EthIcon} alt="" />
          </div>
          <div className={styles["network-label"]}>Ethereum</div>
        </div>
      )}
    </div>
  );
  const DeeperComp = () => (
    <div className={styles["network-info"]}>
      <div className={styles["logo-box"]}>
        <img src={DeeperIcon} alt="" />
      </div>
      <div className={styles["network-label"]}>Deeper Chain</div>
    </div>
  );
  const SwitchBtn = () => (
    <div>
      {network === "BSC" && (
        <Button loading={switchLoading} onClick={() => switchNetwork()} ghost>
          {t("Switch to")} ETH
        </Button>
      )}
      {network === "ETH" && (
        <Button loading={switchLoading} onClick={() => switchNetwork()} ghost>
          {t("Switch to")} BSC
        </Button>
      )}
    </div>
  );

  const DeeperChainAccountListComp = () => (
    <Select
      style={{ width: "100%" }}
      showSearch
      value={deeperAccount}
      placeholder={t("Select a Deeper Chain account")}
      optionFilterProp="children"
      onChange={(val) => {
        setDeeperAccount(val);
      }}
      filterOption={(input, option) =>
        String(option?.label ?? "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      options={deeperChainAccountList.map((it: any) => {
        return {
          label: `${it.meta.name} - ${it.address}`,
          value: it.address,
        };
      })}
    />
  );

  useEffect(() => {
    if (window.ethereum) {
      // 监听钱包切换事件
      window.ethereum.on("accountsChanged", function () {
        window.location.reload();
      });
      window.ethereum.on("chainChanged", function () {
        window.location.reload();
      });
    }
  }, [setNetwork, setEthAccount]);

  const transfer = useMemo(
    () => async () => {
      let tAmount = BigNumber(amount * 1e18).toFixed();
      console.log(direction);
      if (direction === "2") {
        await ethToDeeperChain(
          decodeDeeperChain(deeperAccount),
          ethAccount,
          tAmount
        );
        return;
      }
      if (direction === "1") {
        if (amount - amount < 0) {
          const confirmRes = await new Promise((resolve) => {
            modal.confirm({
              title: t("Insufficient quota"),
              content: t(
                "Today’s quota is no longer able to withdraw this amount of coins. Are you sure you want to continue to apply?"
              ),
              onOk() {
                resolve(true);
              },
              onCancel() {
                resolve(false);
              },
            });
          });
          if (!confirmRes) {
            return;
          }
        }
        const account = deeperChainAccountList.filter(
          (it: any) => it.address === deeperAccount
        )[0];
        const sig = await signString(ethAccount, account);
        await deeperChainToEth(
          decodeDeeperChain(deeperAccount),
          ethAccount,
          tAmount,
          sig
        );
        return;
      }
    },
    [amount, deeperAccount, ethAccount, direction]
  );
  const { run: runTransfer, loading: runTransferLoading } = useRequest(
    transfer,
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
      },
      onError(e) {
        console.log(e);
        modal.error({
          title: "Error",
          content: e.message,
        });
      },
    }
  );

  const RecordComp = useMemo(() => {
    console.log("RecordComp");
    console.log(deeperAccount, ethAccount, chainId);
    if (chainId !== 56) {
      return;
    }
    return (
      <BridgeRecord
        key={deeperAccount + ethAccount + chainId}
        deeperChain={deeperAccount}
        eth={ethAccount}
        chainId={chainId}
      ></BridgeRecord>
    );
  }, [deeperAccount, ethAccount, chainId]);
  return (
    <main className={styles["container"]}>
      {useMemo(
        () => (
          <Header></Header>
        ),
        []
      )}
      <div style={{ overflow: "auto" }}>
        <div>
          <div className={styles["bridge-main"]}>
            <h2 className={styles["bridge-title"]}>
              <img style={{ width: 40 }} src={DprIcon} alt="" />
              DPR BSC {t("Bridge")}
            </h2>

            <div className={styles["network-box"]}>
              <div className={styles["from-box"]}>
                <div className={styles["box-label"]}>
                  <div>{t("Origin Chain")}</div>
                </div>
                {direction === "2" ? (
                  <ETHComp></ETHComp>
                ) : (
                  <DeeperComp></DeeperComp>
                )}
              </div>
              <div className={styles["switch-box"]}>
                <Button
                  shape="circle"
                  icon={<SwapOutlined></SwapOutlined>}
                  size="large"
                  onClick={() => {
                    let newDirection = direction === "1" ? "2" : "1";
                    setDirection(newDirection);
                    window.localStorage.setItem("direction", newDirection);
                  }}
                ></Button>
              </div>
              <div className={styles["to-box"]}>
                <div className={styles["box-label"]}>
                  {t("Destination Chain")}
                </div>
                {direction === "1" ? (
                  <ETHComp></ETHComp>
                ) : (
                  <DeeperComp></DeeperComp>
                )}
              </div>
            </div>
            {direction === "2" && (
              <div className={styles["account-box"]}>
                <div className={styles["box-title"]}>
                  {network} {t(`Address`)}
                </div>
                <div className={styles["input-box"]}>
                  <div
                    style={{ color: "#fff", textAlign: "center", fontSize: 18 }}
                  >
                    {ethAccount}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: 20,
                      color: "#fff",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ color: "#aaa" }}>{t("Balance")}: </div>
                    <Spin spinning={runGetEthDprBalanceLoading}>
                      <div>
                        {Number((Number(ethDprBalance) / 1e18).toFixed(5))} DPR
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            )}
            <div className={styles["account-box"]}>
              <div className={styles["box-title"]}>
                Deeper Chain {t("Address")}
              </div>
              <div className={styles["input-box"]}>
                <DeeperChainAccountListComp></DeeperChainAccountListComp>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 20,
                    color: "#fff",
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                >
                  <div style={{ color: "#aaa" }}>{t("Balance")}: </div>
                  <Spin spinning={runGetDeeperDprBalanceLoading}>
                    <div>
                      {Number((deeperChainBalance / 1e18).toFixed(5))} DPR
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
            {direction === "1" && (
              <div className={styles["account-box"]}>
                <div className={styles["box-title"]}>
                  {network} {t(`Address`)}
                </div>
                <div className={styles["input-box"]}>
                  <div
                    style={{ color: "#fff", textAlign: "center", fontSize: 18 }}
                  >
                    {ethAccount}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: 20,
                      color: "#fff",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ color: "#aaa" }}>{t("Balance")}:</div>
                    <Spin spinning={runGetEthDprBalanceLoading}>
                      <div>
                        {Number((Number(ethDprBalance) / 1e18).toFixed(5))} DPR
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            )}
            <div className={styles["amount-box"]}>
              <div className={styles["box-title"]}>{t("Amount")}</div>
              <div className={styles["input-box"]}>
                <Input
                  placeholder={t("Input transfer amount")}
                  onChange={(e: any) => {
                    if (e.target.value === "") {
                      setAmount(0);
                      return;
                    }
                    let value = e.target.value / 1;
                    if (!value) {
                      return;
                    }
                    setAmount(value);
                  }}
                  value={amount}
                  onBlur={() => {
                    if (amount > Limit.max) {
                      setAmount(Limit.max);
                    } else if (amount < Limit.min) {
                      setAmount(Limit.min);
                    }
                  }}
                  suffix={
                    <div style={{ color: "#fff" }}>
                      <img style={{ width: 30 }} src={DprIcon} alt="" /> DPR
                    </div>
                  }
                ></Input>
              </div>
            </div>
            {direction === "1" && (
              <React.Fragment>
                <div className={styles["burn-rate-box"]}>
                  <div className={styles["box-title"]}>
                    {t("Burn Percentage")}
                  </div>
                  {!changeBurnRateOpen && (
                    <div className={styles["input-box-show"]}>
                      <div>{burnRate} %</div>

                      <Button
                        onClick={() => {
                          setChangeBurnRateOpen(true);
                        }}
                        ghost
                        loading={getBurnRateLoading}
                      >
                        {t("Change")}
                      </Button>
                    </div>
                  )}
                  <div
                    style={{ display: changeBurnRateOpen ? "" : "none" }}
                    className={styles["input-box"]}
                  >
                    <InputNumber<string>
                      ref={burnRateInputRef}
                      min="0"
                      max="10000"
                      step="0.1"
                      precision={1}
                      style={{ width: 150 }}
                      onChange={(val: any) => {
                        setBurnRate(val);
                      }}
                      stringMode
                      placeholder={t("Input burn percentage")}
                      value={burnRate}
                    ></InputNumber>
                    %
                    <Button
                      loading={getBurnRateLoading}
                      onClick={() => {
                        onSetBurnRate(ethAccount, burnRate);
                      }}
                    >
                      {t("Submit")}
                    </Button>
                  </div>
                </div>
                <div style={{ textAlign: "right", marginTop: 10 }}>
                  {minBurnRate > Number(burnRate) * 10 && (
                    <Tag color="error">
                      {t("The current minimum burn percentage has reached")}
                      <span style={{ marginLeft: 10 }}>
                        {minBurnRate / 10} %
                      </span>
                      {t("Your application is likely to fail.")}
                    </Tag>
                  )}
                </div>
              </React.Fragment>
            )}
            {direction === "1" && (
              <React.Fragment>
                <div
                  style={{ borderTop: "1px dashed #ddd", marginTop: "20px" }}
                ></div>
                <div className={styles["burn-rate-box"]}>
                  <div className={styles["box-title"]}>
                    {t("Receive Amount")}
                  </div>
                  <div className={styles["value-box"]}>{amount} DPR</div>
                </div>
                <div className={styles["burn-rate-box"]}>
                  <div className={styles["box-title"]}>{t("Burn Amount")}</div>
                  <div className={styles["value-box"]}>{burnAmount} DPR</div>
                </div>
                <div className={styles["burn-rate-box"]}>
                  <div className={styles["box-title"]}>{t("Total Amount")}</div>
                  <div className={styles["value-box"]}>{totalAmount} DPR</div>
                </div>
              </React.Fragment>
            )}

            <div className={styles["transfer-box"]}>
              <Button
                loading={
                  getCurrentInfoLoading ||
                  runTransferLoading ||
                  getBurnRateLoading ||
                  runGetDeeperDprBalanceLoading
                }
                disabled={isInsufficientBalance}
                onClick={() => {
                  if (chainId !== 56) {
                    return switchNetwork();
                  }
                  return runTransfer();
                }}
              >
                {t("Transfer")}{" "}
                {isInsufficientBalance && (
                  <span style={{ margin: "0 10px" }}>
                    {" "}
                    ( {t("Insufficient Balance")} ){" "}
                  </span>
                )}
              </Button>
            </div>
            <div className={styles["fee-box"]}>
              <RuleBox direction={direction}></RuleBox>
            </div>
          </div>
        </div>
        {contextHolder}
        <div className={styles["data-container"]}>
          <Tabs
            centered
            type="card"
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: t("Transaction Ranking"),
                children: (
                  <div className={styles["data-box"]}>
                    <BridgeRanking
                      setMinBurnRate={(minBurnRate) => {
                        setMinBurnRate(minBurnRate);
                      }}
                    ></BridgeRanking>
                  </div>
                ),
              },
              {
                key: "2",
                label: t("My Transaction"),
                children: (
                  <div className={styles["data-box"]}>
                    {deeperAccount && ethAccount && chainId && RecordComp}
                  </div>
                ),
              },
              {
                key: "3",
                label: t("Burn Record"),
                children: (
                  <div className={styles["data-box"]}>
                    {deeperAccount && ethAccount && chainId && (
                      <BurnRecord></BurnRecord>
                    )}
                  </div>
                ),
              },
            ]}
            onChange={(val) => {
              console.log(val);
            }}
          ></Tabs>
        </div>
      </div>
    </main>
  );
}

export default Bridge;
