import React, { useState } from "react";
import { Table, Button, Modal, Form, Input, message } from "antd";
import { useRequest } from "ahooks";
import {
  getBurnRecords,
  createBurnRecord,
  updateBurnRecordAmount,
  updateBurnRecordHash,
} from "apis/admin"; // 确保这些是正确的API函数路径
import moment from "moment";

const BurnRecordAdmin = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const { data: list = [], refresh } = useRequest(getBurnRecords);

  const { run: create, loading: creating } = useRequest(createBurnRecord, {
    manual: true,
    onSuccess: () => {
      message.success("Burn record created successfully");
      setIsModalVisible(false);
      form.resetFields();
      refresh(); // 刷新列表
    },
    onError: (error) => {
      message.error(`Failed to create burn record: ${error.message}`);
    },
  });

  const { run: updateAmount, loading: updateAmounting } = useRequest(
    updateBurnRecordAmount,
    {
      manual: true,
      onSuccess: () => {
        message.success("updateAmount successfully");
        refresh(); // 刷新列表
      },
      onError: (error) => {
        message.error(`failed updateAmount: ${error.message}`);
      },
    }
  );

  const { run: updateHash, loading: updateHashing } = useRequest(
    updateBurnRecordHash,
    {
      manual: true,
      onSuccess: () => {
        message.success("updateHash successfully");
        refresh(); // 刷新列表
      },
      onError: (error) => {
        message.error(`failed updateHash: ${error.message}`);
      },
    }
  );

  const showCreateModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values: any) => {
    create(values.amount, values.hash);
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={showCreateModal}
        style={{ marginBottom: 16 }}
      >
        Create Burn Record
      </Button>
      <Table
        rowKey="_id"
        columns={[
          {
            title: "_id",
            dataIndex: "_id",
            key: "_id",
          },
          {
            title: "createdAt",
            dataIndex: "createdAt",
            key: "createdAt",
            render(createdAt) {
              return moment(createdAt).format();
            },
          },
          {
            title: "amount",
            dataIndex: "amount",
            key: "amount",
            render(amount) {
              return (
                <div>
                  <div>{amount}</div>
                  <div>{+(amount / 1e18).toFixed(5)}</div>
                </div>
              );
            },
          },
          {
            title: "contractAmount",
            dataIndex: "contractAmount",
            key: "contractAmount",
            render(amount) {
              return (
                <div>
                  <div>{amount}</div>
                  <div>{+(amount / 1e18).toFixed(5)}</div>
                </div>
              );
            },
          },
          {
            title: "settlementCount",
            dataIndex: "settlementCount",
            key: "settlementCount",
          },
          {
            title: "hash",
            dataIndex: "hash",
            key: "hash",
          },
          {
            title: "",
            dataIndex: "",
            key: "_id",
            render(_: any, item) {
              return (
                <div>
                  <Button
                    style={{ marginRight: 10 }}
                    loading={updateAmounting}
                    onClick={() => {
                      updateAmount(item._id);
                    }}
                  >
                    Update Amount
                  </Button>
                  <Button
                    loading={updateHashing}
                    onClick={() => {
                      const hash = window.prompt("Hash");
                      if (!hash) {
                        return;
                      }
                      updateHash(item._id, hash);
                    }}
                  >
                    Update Hash
                  </Button>
                </div>
              );
            },
          },
        ]}
        dataSource={list}
      />
      <Modal
        title="Create Burn Record"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="amount"
            label="Amount"
            rules={[{ required: true, message: "Please input the amount!" }]}
          >
            <Input placeholder="Enter amount in wei" />
          </Form.Item>
          <Form.Item
            name="hash"
            label="Hash"
            rules={[
              { required: true, message: "Please input the transaction hash!" },
            ]}
          >
            <Input placeholder="Enter transaction hash" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={creating}>
              Submit
            </Button>
            <Button onClick={handleCancel} style={{ marginLeft: 8 }}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BurnRecordAdmin;
