import React, { useState, useMemo, useEffect } from "react";

import { Table, Tag, Button } from "antd";

import { useRequest } from "ahooks";
import { encodeDeeperChain } from "w3/polkadot";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getAdminBridgeErrorRecord, updateBridgeSign } from "apis/admin";
import { BridgeRecordItemDto } from "types/types";
import styles from "./BridgeErrorRecord.module.scss";
import Web3 from "web3";

export default function BridgeRecord() {
  const { t } = useTranslation();

  const {
    data: list,
    loading: listLoading,
    refresh,
  } = useRequest(getAdminBridgeErrorRecord, {
    onSuccess(res) {
      console.log(res);
    },
  });

  const { run: runBridgeSign, loading: runBridgeSignLoading } = useRequest(
    updateBridgeSign,
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        refresh();
      },
    }
  );

  const bridgeSignWithMetamask = (messageID: string) => {
    return new Promise((resolve, reject) => {
      const web3 = new Web3(window.ethereum); // 初始化 web3 实例

      const message = web3.utils.soliditySha3(messageID) || "";
      console.log(message);
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts: string[]) => {
          // 获取到用户的账户数组
          console.log(accounts);

          if (accounts.length === 0) {
            reject(new Error("No accounts found"));
            return;
          }

          const account = accounts[0]; // 假设用户选择的是第一个账户
          web3.eth.personal
            .sign(message, account, "")
            .then((signature: string) => {
              // 输出签名
              resolve(signature);
              console.log("Signed message:", signature);
            })
            .catch((err: Error) => {
              // 处理错误
              console.error(err);
              reject(err);
            });
        })
        .catch((error: Error) => {
          // 用户拒绝了访问
          reject(error);
          console.error("User denied account access", error);
        });
    });
  };

  const columns = [
    {
      title: t("Time"),
      dataIndex: "createdAt",
      key: "createdAt",
      render(createdAt: string) {
        return moment(createdAt).format();
      },
      sorter: (a: BridgeRecordItemDto, b: BridgeRecordItemDto) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      },
    },
    {
      title: t("Period"),
      dataIndex: "batch",
      key: "batch",
      render(batch: number) {
        if (!batch) {
          return;
        }
        return (
          <div style={{ fontSize: 12 }}>
            <div>{moment(batch).subtract(12, "hours").format()}</div>
            <div>-</div>
            <div>{moment(batch).format()}</div>
          </div>
        );
      },
    },
    {
      title: t("MessageID"),
      dataIndex: "MessageID",
      key: "MessageID",
      className: styles["word-break"],
    },
    {
      title: t("From"),
      dataIndex: "from",
      key: "from",
      className: styles["word-break"],
      render(_: any, item: BridgeRecordItemDto) {
        const data = encodeDeeperChain(item.substrateSender);
        return data;
      },
    },
    {
      title: t("To"),
      dataIndex: "to",
      key: "to",
      className: styles["word-break"],
      render(_: any, item: BridgeRecordItemDto) {
        const data = item.recipient;
        return data;
      },
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render(amount: string, item: BridgeRecordItemDto) {
        return (
          <div style={{ whiteSpace: "nowrap", fontSize: 12 }}>
            <div>
              {t("Receive Amount")}:{" "}
              {Number((Number(item.contractAmount) / 1e18).toFixed(5))} DPR
            </div>
            <div>
              {t("Burn Amount")}:{" "}
              {Number((Number(item.burnAmount) / 1e18).toFixed(5))} DPR
            </div>
            <div>
              {t("Total Amount")}:{" "}
              {Number((Number(item.amount) / 1e18).toFixed(5))} DPR
            </div>
          </div>
        );
      },
    },
    {
      title: t("Burn Percentage"),
      dataIndex: "burnRateInt",
      key: "burnRateInt",
      render(burnRateInt: number) {
        return <div style={{ whiteSpace: "nowrap" }}>{burnRateInt / 10} %</div>;
      },
    },
    {
      title: t("Occupied amount"),
      dataIndex: "rankingTotal",
      key: "rankingTotal",
    },
    {
      title: t("withdraw"),
      dataIndex: "withdraw",
      key: "withdraw",
      render(_: any, item: BridgeRecordItemDto) {
        return (
          <div>
            {item.send && <Tag color="success">send</Tag>}
            {item.withdraw && <Tag color="success">withdraw</Tag>}
          </div>
        );
      },
    },
    {
      title: t("BridgeSign"),
      dataIndex: "bridgeSign",
      key: "bridgeSign",
      className: styles["word-break"],
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render(_: any, item: BridgeRecordItemDto) {
        return (
          <div>
            {item.win ? (
              <div>
                <Tag color="success">{t("Win")}</Tag>
              </div>
            ) : (
              <div>
                <Tag color="success">{t("Not win")}</Tag>
              </div>
            )}
            {item.ballot && (
              <div>
                <Tag color="warning">{t("Draw lots")}</Tag>
              </div>
            )}
            {item.isError && (
              <div style={{ margin: "5px 0" }}>
                <Tag
                  color="error"
                  style={{ maxWidth: 100, whiteSpace: "normal" }}
                >
                  {item.errorMessage}
                </Tag>
              </div>
            )}
            {item.send && !item.bridgeSign && !item.isError && (
              <div>
                <Button
                  loading={runBridgeSignLoading}
                  onClick={async () => {
                    let bridgeSign = window.prompt("BridgeSign");
                    if (!bridgeSign) {
                      try {
                        // 等待异步操作完成，并且假设 bridgeSignWithMetamask 正确地返回了一个 string
                        bridgeSign = (await bridgeSignWithMetamask(
                          item.MessageID
                        )) as string;
                      } catch (error) {
                        // 处理错误，例如提示用户错误信息
                        console.error("Error signing with MetaMask:", error);
                        return;
                      }
                    }
                    // 确保 bridgeSign 不是 null 或 undefined
                    if (bridgeSign) {
                      runBridgeSign(item._id, bridgeSign);
                    }
                  }}
                >
                  BridgeSign
                </Button>
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Table<BridgeRecordItemDto>
        size="small"
        rowKey="_id"
        dataSource={list}
        loading={listLoading}
        columns={columns}
      />
    </div>
  );
}
