import React from "react";

import { Table, Button, Tag, Modal } from "antd";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useRequest } from "ahooks";
import { encodeDeeperChain } from "w3/polkadot";
import { confirmWithdraw } from "w3/eth/index";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getBridgeRecord } from "apis/bridge";
import { BridgeRecordItemDto } from "types/types";
import styles from "./Ranking.module.scss";

export default function BridgeRecord({
  deeperChain,
  eth,
  chainId,
}: {
  deeperChain: string;
  eth: string;
  chainId: number;
}) {
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const { data: list, loading: listLoading } = useRequest(
    async (params) => {
      return getBridgeRecord(params);
    },
    {
      defaultParams: [
        {
          wallet: eth,
        },
      ],
      pollingInterval: 10000,
      onSuccess(res) {
        console.log(res);
      },
    }
  );

  const { run: runConfirmWithdraw, loading: withdrawLoading } = useRequest(
    confirmWithdraw,
    {
      manual: true,
      onSuccess(res) {},
      onError(e) {
        console.log(e);
        modal.error({
          title: "Error",
          content: e.message,
        });
      },
    }
  );

  const columns = [
    {
      title: t("Time"),
      dataIndex: "createdAt",
      key: "createdAt",
      render(createdAt: string) {
        return moment(createdAt).format();
      },
    },
    {
      title: t("Period"),
      dataIndex: "batch",
      key: "batch",
      render(batch: number) {
        if (!batch) {
          return;
        }
        return (
          <div style={{ fontSize: 12 }}>
            <div>{moment(batch).subtract(12, "hours").format()}</div>
            <div>-</div>
            <div>{moment(batch).format()}</div>
          </div>
        );
      },
    },
    {
      title: t("From"),
      dataIndex: "from",
      key: "from",
      className: styles["word-break"],
      render(_: any, item: BridgeRecordItemDto) {
        if (item.direction === "1") {
          return encodeDeeperChain(item.substrateSender);
        }
        if (item.direction === "2") {
          return item.sender;
        }
      },
    },
    {
      title: t("To"),
      dataIndex: "to",
      key: "to",
      className: styles["word-break"],
      render(_: any, item: BridgeRecordItemDto) {
        if (item.direction === "1") {
          return item.recipient;
        }
        if (item.direction === "2") {
          return encodeDeeperChain(item.recipient);
        }
      },
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render(amount: string, item: BridgeRecordItemDto) {
        return (
          <div style={{ whiteSpace: "nowrap", fontSize: 12 }}>
            <div>
              {t("Receive Amount")}:{" "}
              {Number(
                (
                  Number(item.contractAmount || Number(item.amount)) / 1e18
                ).toFixed(5)
              )}{" "}
              DPR
            </div>
            <div>
              {t("Burn Amount")}:{" "}
              {Number((Number(item.burnAmount || 0) / 1e18).toFixed(5))} DPR
            </div>
            <div>
              {t("Total Amount")}:{" "}
              {Number((Number(item.amount) / 1e18).toFixed(5))} DPR
            </div>
          </div>
        );
      },
    },
    {
      title: t("Burn Percentage"),
      dataIndex: "burnRateInt",
      key: "burnRateInt",
      render(burnRateInt: number) {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {burnRateInt ? burnRateInt / 10 : 0} %
          </div>
        );
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render(_: any, item: BridgeRecordItemDto) {
        if (item.isError) {
          return (
            <React.Fragment>
              <Tag icon={<CloseCircleOutlined />} color="error">
                <span
                  style={{ wordBreak: "break-all", whiteSpace: "break-spaces" }}
                >
                  {t("error")}: {item.errorMessage}
                </span>
              </Tag>
              {item.win === false && (
                <div>
                  <Tag color="error">{t("Not win")}</Tag>
                </div>
              )}
            </React.Fragment>
          );
        }
        if (item.bridgeSign) {
          return (
            <div>
              <Tag icon={<CheckCircleOutlined />} color="success">
                {t("success")}
              </Tag>
              {!item.withdraw && (
                <Button
                  onClick={() => {
                    runConfirmWithdraw(item.MessageID, item.bridgeSign, eth);
                  }}
                  loading={withdrawLoading}
                  type="primary"
                  style={{ marginTop: 5 }}
                  size="small"
                >
                  {t("Withdraw")}
                </Button>
              )}
            </div>
          );
        }
        if (item.done) {
          return (
            <div>
              <Tag icon={<CheckCircleOutlined />} color="success">
                {t("success")}
              </Tag>
            </div>
          );
        }

        return (
          <Tag icon={<SyncOutlined spin />} color="default">
            {t("pending")}
          </Tag>
        );
      },
    },
  ];
  return (
    <div style={{ maxWidth: 1200 }}>
      <Table<BridgeRecordItemDto>
        loading={listLoading}
        size="small"
        rowKey="_id"
        dataSource={list}
        columns={columns}
      />
      {contextHolder}
    </div>
  );
}
