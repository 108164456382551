import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Bridge from "./Bridge/Bridge";
import Admin from "./Admin/Admin";
import BridgeRecord from "Admin/BridgeRecord/BridgeRecord";
import { Login } from "Admin/Login/Login";
import User from "./Admin/User/User";
import Settlement from "Admin/Settlement/Settlement";
import BurnRecordAdmin from "Admin/BurnRecord/BurnRecord";
import BurnRecord from "BurnRecord/BurnRecord";
import BridgeErrorRecord from "Admin/BridgeErrorRecord/BridgeErrorRecord";

export default function Router() {
  return (
    <Routes>
      <Route path="/bridge" element={<Bridge />}></Route>
      <Route path="/burn" element={<BurnRecord />}></Route>
      <Route path="/admin/login" element={<Login></Login>}></Route>
      <Route path="admin" element={<Admin />}>
        <Route path="user" element={<User></User>}></Route>
        <Route
          path="bridgeRecord"
          element={<BridgeRecord></BridgeRecord>}
        ></Route>
        <Route
          path="bridgeErrorRecord"
          element={<BridgeErrorRecord></BridgeErrorRecord>}
        ></Route>
        <Route path="settlement" element={<Settlement></Settlement>}></Route>
        <Route
          path="burnRecord"
          element={<BurnRecordAdmin></BurnRecordAdmin>}
        ></Route>
      </Route>
      <Route path="*" element={<Navigate to="/bridge" />}></Route>
    </Routes>
  );
}
