import BurnRecordComp from "Bridge/BurnRecord";
import { useMemo } from "react";
import Header from "components/Header/Header";
import { useTranslation } from "react-i18next";
export default function BurnRecord() {
  const { t } = useTranslation();
  return (
    <main>
      {useMemo(
        () => (
          <Header></Header>
        ),
        []
      )}
      <h3 style={{ textAlign: "center", margin: "20px 0" }}>
        {t("Burn Record")}
      </h3>
      <div style={{ maxWidth: 1200, width: "100%", margin: "20px auto" }}>
        <BurnRecordComp></BurnRecordComp>
      </div>
    </main>
  );
}
