import axios from "axios";
import { message } from "antd";
import i18n from "../i18n";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://api-device.deeper.network/"
      : "http://127.0.0.1:7001/",
});

export default function fetch(config: any) {
  return instance(config)
    .then((res) => res.data)
    .catch((e) => {
      message.error(i18n.t(`Res.${e.response.data}`));
      return Promise.reject(e);
    });
}
