import { BridgeRecordItemDto } from "types/types";
import fetch from "./fetch";

export async function getBridgeRecord(params: {
  wallet: string;
}): Promise<BridgeRecordItemDto[]> {
  return fetch({
    url: "/bridge/bsc/records",
    params,
  }).then((res) => res.data);
}

export async function getBridgeRanking(params: {
  batch?: number;
}): Promise<BridgeRecordItemDto[]> {
  return fetch({
    url: "/bridge/bsc/ranking",
    params,
  });
}

export async function getBridgeRemainingAmount(
  account: string
): Promise<number> {
  return 0;
}

export function getBurnRate(address: string) {
  return fetch({
    url: "/bridge/bsc/burnRate",
    params: {
      address,
    },
  });
}

export function setBurnRate(
  address: string,
  burnRate: number,
  signature: string
) {
  return fetch({
    url: "/bridge/bsc/burnRate",
    method: "POST",
    data: {
      address,
      burnRate,
      signature,
    },
  });
}

export function getBurnRecord() {
  return fetch({
    url: "/bridge/bsc/burnRecord",
  });
}
