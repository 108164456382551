import { useRequest } from "ahooks";
import { getBurnRecord } from "apis/bridge";

import React from "react";
import { DownOutlined } from "@ant-design/icons";
import type { TableColumnsType } from "antd";
import { Badge, Dropdown, Space, Table } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
interface BurnRecordItemDto {
  key: React.Key;
  createdAt: string;
  amount: string;
  hash: string;
}

interface SettlementItemDto {
  key: React.Key;
  batch: number;
  burnAmount: string;
  burnRecordId: {
    _id: string;
  };
}

export default function BurnRecord() {
  const { t } = useTranslation();
  const { data: list = [], loading } = useRequest(
    async () => {
      const data = await getBurnRecord();
      let key = 0;
      let listObj: { [key: string]: any } = {};
      data.forEach((it: SettlementItemDto) => {
        const burnRecordId = it.burnRecordId._id;
        if (!listObj[burnRecordId]) {
          listObj[burnRecordId] = {
            key,
            ...it.burnRecordId,
            records: [],
          };
          key++;
        }
        listObj[burnRecordId].records.push(it);
      });
      return Object.values(listObj);
    },
    {
      onSuccess(res) {
        console.log(res);
      },
    }
  );

  const columns: TableColumnsType<BurnRecordItemDto> = [
    {
      title: t("Time"),
      dataIndex: "createdAt",
      key: "createdAt",
      render(createdAt) {
        return moment(createdAt).format();
      },
    },
    {
      title: t("Burn Amount"),
      dataIndex: "amount",
      key: "amount",
      render(amount) {
        return +(amount / 1e18).toFixed(5);
      },
    },
    {
      title: t("hash"),
      dataIndex: "hash",
      key: "hash",
      render: (hash) => (
        <a
          href={`https://etherscan.io/tx/${hash}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {hash}
        </a>
      ),
    },
  ];

  const expandedRowRender = (item: any) => {
    const columns: TableColumnsType<SettlementItemDto> = [
      {
        title: t("Period"),
        dataIndex: "batch",
        key: "batch",
        render(batch: number) {
          if (!batch) {
            return;
          }
          return (
            <div style={{ fontSize: 12 }}>
              <div>{moment(batch).subtract(12, "hours").format()}</div>
              <div>-</div>
              <div>{moment(batch).format()}</div>
            </div>
          );
        },
      },
      {
        title: t("Burn Amount"),
        key: "burnAmount",
        dataIndex: "burnAmount",
        render(burnAmount) {
          return +(burnAmount / 1e18).toFixed(6);
        },
      },
    ];

    return (
      <Table
        rowKey={"_id"}
        columns={columns}
        dataSource={item.records}
        pagination={false}
      />
    );
  };

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        rowKey="key"
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: [0],
        }}
        dataSource={list}
      />
    </div>
  );
}
