import React from "react";
import { Table, Tag, Button } from "antd";
import { useRequest } from "ahooks";
import { getSettlement, setSettlementBurnRecord } from "apis/admin";
import moment from "moment";
import { SettlementItemDto } from "types/types";

export default function Settlement() {
  const { data: list = [], refresh } = useRequest(getSettlement, {
    onSuccess(res) {
      console.log(res);
    },
  });

  const {
    run: runSetSettlementBurnRecord,
    loading: runSetSettlementBurnRecordLoading,
  } = useRequest(setSettlementBurnRecord, {
    manual: true,
    onSuccess(res) {
      refresh();
      console.log(res);
    },
  });

  return (
    <div>
      <Table<SettlementItemDto>
        rowKey="_id"
        columns={[
          {
            title: "Period",
            dataIndex: "batch",
            key: "batch",
            render(batch: number) {
              if (!batch) {
                return;
              }
              return (
                <div style={{ fontSize: 12 }}>
                  <div>{moment(batch).subtract(12, "hours").format()}</div>
                  <div>-</div>
                  <div>{moment(batch).format()}</div>
                </div>
              );
            },
          },
          {
            title: "batch",
            key: "batch",
            dataIndex: "batch",
          },
          {
            title: "applicationCount",
            key: "applicationCount",
            dataIndex: "applicationCount",
          },
          {
            title: "amount",
            key: "amount",
            dataIndex: "amount",
            render(amount) {
              return +(amount / 1e18).toFixed(6);
            },
          },
          {
            title: "contractAmount",
            key: "contractAmount",
            dataIndex: "contractAmount",
            render(contractAmount) {
              return +(contractAmount / 1e18).toFixed(6);
            },
          },
          {
            title: "burnAmount",
            key: "burnAmount",
            dataIndex: "burnAmount",
            render(burnAmount) {
              return +(burnAmount / 1e18).toFixed(6);
            },
          },
          {
            title: "confirmCount",
            key: "confirmCount",
            dataIndex: "confirmCount",
          },
          {
            title: "burnRecord",
            key: "burnRecordId",
            dataIndex: "burnRecordId",
            render(burnRecordId, item) {
              return burnRecordId;
            },
          },
          {
            title: "done",
            key: "done",
            dataIndex: "done",
            render(done) {
              return <div>{done && <Tag color="success">done</Tag>}</div>;
            },
          },
          {
            render(item) {
              return (
                <div>
                  <Button
                    onClick={() => {
                      const burnRecordId = window.prompt("Burn Record Id");
                      runSetSettlementBurnRecord(item._id, burnRecordId || "");
                    }}
                  >
                    set burn record id
                  </Button>
                </div>
              );
            },
          },
        ]}
        dataSource={list}
      ></Table>
    </div>
  );
}
