import { BridgeRecordItemDto, BurnRecordItemDto } from "types/types";
import fetch from "./adminFetch";

export async function getAdminBridgeRanking(params: {
  batch?: number;
}): Promise<BridgeRecordItemDto[]> {
  return fetch({
    url: "/bsc/ranking",
    params,
  });
}

export async function getAdminBridgeErrorRecord(): Promise<
  BridgeRecordItemDto[]
> {
  return fetch({
    url: "/bsc/bridgeErrorRecord",
  });
}

export async function updateBridgeSign(id: string, value: string) {
  return fetch({
    url: "/bsc/records/bridgeSign",
    method: "PUT",
    data: {
      id,
      value,
    },
  });
}

export async function getSettlement() {
  return fetch({
    url: "/bsc/settlement",
  });
}

// Method to get burn records
export async function getBurnRecords(): Promise<BurnRecordItemDto[]> {
  return fetch({
    url: "/bsc/burnRecord",
  });
}

// Method to create a new burn record
export async function createBurnRecord(
  amount: string,
  hash: string
): Promise<BurnRecordItemDto> {
  return fetch({
    url: "/bsc/burnRecord",
    method: "POST",
    data: {
      amount,
      hash,
    },
  });
}

// Method to update the hash of an existing burn record
export async function updateBurnRecordHash(id: string, hash: string) {
  return fetch({
    url: "/bsc/burnRecord",
    method: "PUT",
    data: {
      id,
      hash,
    },
  });
}

// Method to update the amount of an existing burn record
export async function updateBurnRecordAmount(id: string) {
  return fetch({
    url: "/bsc/burnRecord/updateAmount",
    method: "POST",
    data: {
      id,
    },
  });
}

export function setSettlementBurnRecord(id: string, burnRecordId: string) {
  return fetch({
    url: "/bsc/settlement/bindBurnRecord",
    method: "POST",
    data: {
      id,
      burnRecordId,
    },
  });
}
