import { zh as ResZh } from "./res";

const zh = {
  Res: {
    ...ResZh,
  },
  "Deeper Network Staking": "Deeper Network 质押",
  Register: "注册",
  Email: "邮箱",
  Username: "用户名",
  "Verification Code": "验证码",
  Password: "密码",
  "New Password": "新密码",
  "Repeat Password": "重复密码",
  Send: "发送验证码",
  Login: "登录",
  "Reset Password": "重置密码",
  "Forget password": "忘记密码",
  Referer: "推荐人",

  "Please input your email!": "请输入邮箱",
  "Please input your username!": "请输入用户名",
  "Please input your verification code!": "请输入验证码",
  "register|Please input your password!":
    "请输入一个复杂的密码(至少8位，包含数字、字母)",
  "Please input your password!": "请输入密码",
  "invalid email": "邮箱地址不正确。",
  "Confirmed passwords do not match": "两次密码不一致。",

  Staking: "质押",
  "Create a staking": "创建质押",
  "Staking Information": "质押信息",
  "Device SN": "设备SN号",
  "Device Deeper Chain": "设备绑定的DeeperChain钱包",
  "Please bind the DeeperChain wallet on the device":
    "请在设备上绑定DeeperChain钱包",
  "Deeper Chain Staked": "BSC质押的DeeperChain钱包",
  "USDC Staked": "质押的USDC数量",
  "Staking Type": "质押类型",
  "Current Credit": "当前信用分",
  "Credit Tier": "信用等级",
  "DPR Price": "当前DPR价格",
  "for reference only": "仅供参考，以实际为准",
  "Staking Record": "质押记录",
  Time: "时间",
  Credit: "信用分",
  Status: "状态",
  success: "成功",
  pending: "执行中",
  "Max Credit Tier": "已到最大信用等级",
  "USDC needed": "需要USDC",
  APY: "年化收益率",
  "DPR will be staked": "DPR质押的数量",
  Pending: "执行中",
  "Add Stake": "开始质押",
  Detail: "详情",
  "Do you Want to delete this item?": "确定要删除该记录吗？",
  Delete: "删除",
  "User denied transaction signature.": "用户取消",
  Error: "出错了",
  "Please connect to the BSC mainnet": "请连接BSC主网",
  "Please install the MetaMask plugin or use this system in the mobile wallet App.":
    "请安装MetaMask插件或者在手机钱包App中使用本系统。",
  "Basic Information": "基本信息",
  "BSC Wallet Information": "BSC 钱包信息",
  "Wallet Address": "钱包地址",
  "Please connect to the registered BEP20 address": "请连接注册的BSC钱包",
  "USDC Balance": "USDC 余额",
  Approve: "授权",
  Stake: "质押",
  "Reset password": "重置密码",
  Caution: "请注意",
  "The Deeper Chain wallet cannot be staked with USDC, please go to the deivce backend to stake.":
    "该DeeperChain钱包无法用USDC质押，请到设备后台质押。",
  empty: "未入网",
  "Insufficient balance": "余额不足",
  "DPR daily reward": "每日收益",
  "DPR Swaped": "兑换的DPR数量",

  Wallet: "钱包",
  Search: "搜索",
  "Watch a Wallet": "导入观察钱包",
  Refresh: "刷新信息",
  "Deeper Chain Address": "Deeper Chain 钱包地址",
  Submit: "提交",
  Cancel: "取消",
  CAMPAIGN_ID_NULL: "未入网",
  CAMPAIGN_ID_0: "黄金创世节点",
  CAMPAIGN_ID_1: "白银创世节点",
  CAMPAIGN_ID_2: "Basic Mining 1.0",
  CAMPAIGN_ID_3: "DPRB Mining",
  CAMPAIGN_ID_4: "Basic Mining 2.0",
  CAMPAIGN_ID_5: "Easy Mining",
  "Input Deeper Chain Address": "请输入 Deeper Chain 钱包地址",

  tutorial: {
    Step1: "第一步",
    Step1Content: "在设备后台创建DeeperChain钱包",
    Step2: "第二步",
    Step3: "第三步",
    Step4: "第四步",
    Step4Content: "打开BSC质押网站，注册用户",
    Step5: "第五步",
    Step5Content: "登录BSC质押网站，选择质押等级并质押",
    Step6: "第六步",
    Step6Content: "登录BSC质押网站，刷新页面检查升级情况",
  },

  "Select a Deeper Chain account": "请选择一个 Deeper Chain 钱包",
  Bridge: "跨链桥",
  "Destination Chain": "转入区块链",
  "Origin Chain": "转出区块链",
  Address: "地址",
  Balance: "余额",
  "Input transfer amount": "请输入转账数量",
  "Switch to": "切换到",
  Amount: "数量",
  "Daily limit: 2,000,000 DPR for each address, 2,000,000 DPR for bridge.":
    "每日限额：每个地址 2,000,000 DPR，整个跨链桥 2,000,000 DPR",
  "Remaining amount": "剩余额度",
  Transfer: "转账",

  From: "转出地址",
  To: "转入地址",

  Withdraw: "提现",
  error: "错误",
  "No.": "编号",
  Description: "描述",
  "Remaining Rewards": "剩余奖励",
  "Day(s)": "天",
  "Next Day's Reward": "下一天奖励",
  "Baseline Reward": "基准奖励",
  Day: "天",
  "Please install": "请安装",
  "and import wallet.": "并导入钱包",
  "Install polkadot{.js} extension": "请设置polkadot{.js} extension",
  "Download template file": "下载模板文件",
  "Click to Upload": "上传文件",
  "Add to MetaMask": "添加到MetaMask",

  "You will be transferred to the address below": "您将会被转账到下面的地址",

  "This wallet has no staking income and cannot cross-chain.":
    "该钱包没有质押收益，无法跨链。",
  Fee: "手续费",

  "Submitted successfully.": "提交成功!",
  "Cancel Application": "取消这笔申请",
  Confirm: "确认",
  Cancelled: "已取消",
  "Are you sure you want to cancel this application?": "确认要取消这笔申请吗？",
  "Net Amount": "到账数量",
  "Transfer failed.": "转账失败。",
  "Exchanged DPR": "已兑换DPR",
  "Transfer-out Address": "转出地址",
  "Transfer-in Address": "转入地址",
  "Insufficient quota": "额度不足",
  "Today’s quota is no longer able to withdraw this amount of DPR. Are you sure you want to continue to apply?":
    "今日额度已经无法提这些数量的DPR, 确定要继续申请吗？",
  "Please input staking code.": "请输入质押码。",
  "Invalid staking code": "质押码错误",
  "Please input device SN.": "请输入设备SN号码",
  "Are you sure you want to modify the burn percentage? Modifying the burn percentage will affect all uncollected withdrawal requests.":
    "确定要修改燃烧率吗？修改燃烧率将会影响所有未收录的提币申请。",
  Change: "修改",
  "Burn Percentage": "燃烧率",
  "Receive Amount": "收币数量",
  "Burn Amount": "燃烧数量",
  "Total Amount": "总共需要",
  "Transaction Ranking": "申请排行",
  "My Transaction": "我的申请",
  "Occupied amount": "额度占用",
  "Minimum Burn Percentage": "最小燃烧率",
  "Total Win Amount": "跨链总数量",
  "Total Burn Amount": "燃烧总数量",
  "Insufficient Balance": "余额不足",
  Period: "周期",
  Example: "例子",
  Win: "入围",
  "Draw lots": "抽签",
  "Burn Record": "销毁记录",
  "Coming soon": "即将更新",
  "DPR <-> BSC Cross-Chain Bridge Rules": "DPR <-> BSC跨链桥规则",
  "The burn mechanism does not apply when bridging from BSC to Deeper Chain. The cross-chain transfer will be processed immediately.":
    "从BSC跨链到DeeperChain这个方向，不需要设置燃烧率，跨链会立即被处理。",
  "Please select a burn percentage before transacting on the Deeper Chain to BSC cross-chain transfer. The default is 0% and the maximum is 10,000%. The minimum change is 0.1%. The burned DPR does not come from the transferred balance. You must have sufficient DPR in your Deeper wallet. ":
    "用户需要在申请跨链之前设置燃烧率，不设置默认是0%，最大值10000%，最小变动是0.1%",
  "The burn percent set by the user will affect future cross-chain transactions. If the burn percent is not modified, it will remain in effect until the next transaction. ":
    "用户设置的燃烧率将会对未来发起的跨链申请产生影响，如果不主动修改燃烧率，将会一直生效。",
  "The cross-chain bridge has a cycle of 12 hours, with a cross-chain quota of 600,000 DPR per cycle. The maximum amount that can be requested per address is 10,000 DPR, and the minimum is 1,000 DPR. Users can apply multiple times in a cycle. The transaction is executed at the end of each cycle. ":
    "跨链桥每12小时为一个周期，每个周期60万DPR的跨链额度，每个地址最大10000DPR，最小1000DPR申请额度。用户可以在一个周期申请多次。",
  "The cross-chain bridge processes cross-chain applications once per cycle. After the cycle ends, the transactions will be sorted by burn percentage from highest to lowest then the transactions with the highest burn rates will be prioritized. Users will not lose DPR if their transaction doesn't go through.":
    "跨链桥每个周期处理一次跨链申请，周期结束之后，将会按照燃烧率从大到小排序，燃烧率大的申请优先处理。",
  "For cross-chain transactions with the same burn percentage, transactions with smaller amounts will be prioritized. If the transaction amounts are also the same, and total transactions have exceeded the current cycle limit, then these transactions will be processed randomly until the limit is reached.":
    "燃烧率一样的跨链申请，优先处理申请数量小的申请。如果申请数量也一样，并且全部处理已经超过了当前周期的限额，那么将会随机处理这些申请，直到满额。",
  "The system will count the total burn amount each cycle, and the DPR will be uniformly burned and announced every month.":
    "系统每个周期都会统计燃烧总量，每个月会统一进行销毁并公示。",
  "User A: cross-chain transaction of 10,000 DPR with a burn percentage of 10%.":
    "用户A: 跨链 10,000 DPR，设置销毁率为 10%",
  "User B: cross-chain transaction of 5,000 DPR with a burn percentage of 10%.":
    "用户B: 跨链 5,000 DPR，设置销毁率为 10%",
  "User C: cross-chain transaction of 5,000 DPR with a burn percentage of 5%.":
    "用户C: 跨链 5,000 DPR，设置销毁率为 5%",
  [`In this example, user B's transaction will be prioritized. User B's transaction total will then be 5,500 DPR, of which 5,000 DPR is the cross-chain transaction amount and 500 DPR will be part of the burn pool.`]:
    "在这个例子中，用户B的交易将被优先处理。用户B的转账总额为5,500 DPR，其中5,000 DPR为跨链数量，500 DPR将被销毁。",
  "User A: cross-chain transaction of 10,000 DPR with a burn percentage of 120%.":
    "用户A: 跨链 10,000 DPR，设置销毁率为 120%",
  [`If User A's transaction is prioritized for the current cycle, then User A will be deducted 22,000 DPR, of which 10,000 DPR is the cross-chain transaction amount and 12,000 DPR will be part of the burn pool. If the user's balance has insufficient DPR to deduct 22,000 DPR, then the transaction will not be executed and leave room for the next transaction.`]:
    "如果用户A的交易在本周期优先，则用户A将被扣除22,000 DPR，其中10,000 DPR为跨链数量，12,000 DPR将作为销毁池的一部分。如果用户的DPR余额不足以扣除22,000 DPR，则该交易将不会执行，为下一笔交易留出空间。",
};

export default zh;
