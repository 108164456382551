import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
import { Layout, Menu, Button, Drawer } from "antd";
import React, { useState } from "react";
import logoImg from "assets/imgs/logo-white.png";
import logoImgSm from "assets/imgs/logo-white-sm.png";
import logoImgDark from "assets/imgs/logo.png";

import { useTranslation } from "react-i18next";

import useAuth from "hooks/useAuth";
import { useResponsive } from "ahooks";
import styles from "./Admin.module.scss";

const { Header, Sider, Content } = Layout;

function User() {
  const [collapsed, setCollapsed] = useState(false);
  const { token } = useAuth(true);
  const responsive = useResponsive();

  const location = useLocation();
  const navigate = useNavigate();

  function handleClick(e: { key: string }) {
    navigate(e.key);
    if (!responsive.md) {
      setCollapsed(!collapsed);
    }
  }

  const { t, i18n } = useTranslation();
  const changeLocale = () => {
    const currentLocale = i18n.language;
    if (currentLocale == "en") {
      i18n.changeLanguage("zh");
      window.localStorage.setItem("locale", "zh");
    } else {
      i18n.changeLanguage("en");
      window.localStorage.setItem("locale", "en");
    }
  };

  if (!token) {
    return <Navigate to="/" />;
  }

  const menuItem = [
    {
      key: "/admin/user",
      icon: <GlobalOutlined />,
      label: t("User"),
    },
    {
      key: "/admin/bridgeRecord",
      icon: <GlobalOutlined />,
      label: t("BridgeRecord"),
    },
    {
      key: "/admin/bridgeErrorRecord",
      icon: <GlobalOutlined />,
      label: t("BridgeErrorRecord"),
    },
    {
      key: "/admin/settlement",
      icon: <GlobalOutlined />,
      label: t("Settlement"),
    },
    {
      key: "/admin/burnRecord",
      icon: <GlobalOutlined />,
      label: t("BurnRecord"),
    },
  ];

  return (
    <Layout className={styles.AdminContainer}>
      {responsive.md ? (
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className={styles.logo}>
            <img src={collapsed ? logoImgSm : logoImg} alt="" />
          </div>
          <div className={styles["locale-box"]}>
            <Button onClick={changeLocale} type="link">
              en / 中文
            </Button>
          </div>

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[location.pathname]}
            onClick={(e) => handleClick(e)}
            items={menuItem}
          />
        </Sider>
      ) : (
        <Drawer
          closable={false}
          width={180}
          placement="right"
          onClose={() => setCollapsed(!collapsed)}
          visible={collapsed}
        >
          <div className={styles["mobile-side"]}>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[location.pathname]}
              onClick={(e) => handleClick(e)}
              items={menuItem}
            />
          </div>
        </Drawer>
      )}
      <Layout className={styles["site-layout"]}>
        <Header
          className={styles["site-layout-background"]}
          style={{ padding: 0 }}
        >
          <div className={styles["header-container"]}>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: styles.trigger,
                onClick: () => setCollapsed(!collapsed),
              }
            )}
            {!responsive.md && (
              <>
                <div className={styles["logo-box"]}>
                  <img src={logoImgDark} alt="" />
                </div>
                <div>
                  <Button onClick={changeLocale} type="link">
                    en / 中文
                  </Button>
                </div>
              </>
            )}
          </div>
        </Header>
        <Content className={styles.mainContainer}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default User;
