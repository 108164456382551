import { useLocation, Navigate } from "react-router-dom";

export function Login() {
  const location = useLocation();

  // 使用URLSearchParams来解析查询字符串
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token"); // 获取token的值

  if (token) {
    window.localStorage.setItem("adminToken", token);
    console.log(token);
    return <Navigate to="/admin/bridgeRecord"></Navigate>;
  }
  return <div>loading...</div>;
}
