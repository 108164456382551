import { ApiPromise, WsProvider } from "@polkadot/api";

const {
  web3Accounts,
  web3Enable,
  web3FromSource,
} = require("@polkadot/extension-dapp");
const { u8aToHex, hexToU8a, stringToHex } = require("@polkadot/util");
const { encodeAddress, decodeAddress } = require("@polkadot/util-crypto");

const sleep = (t) => new Promise((r) => setTimeout(r, t));
const deeperChainWss =
  process.env.NODE_ENV === "production"
    ? "wss://mainnet-deeper-chain.deeper.network"
    : // : "wss://mainnet-dev.deeper.network/";
      "wss://mainnet-deeper-chain.deeper.network";

let globalApi;

async function getApi() {
  if (globalApi && globalApi.isConnected) {
    return globalApi;
  }
  if (globalApi) {
    await globalApi.disconnect();
  }

  const wsProvider = new WsProvider(deeperChainWss);
  globalApi = await ApiPromise.create({ provider: wsProvider });
  return globalApi;
}

export async function getPolkadotAccountList(lastTryTimes = 0) {
  let tryTimes = lastTryTimes + 1;
  try {
    // 启用 Polkadot.js 扩展程序
    await web3Enable("DeeperChain Bridge");
    // 获取所有钱包信息
    const accounts = await web3Accounts();
    if (accounts.length == 0 && tryTimes <= 5) {
      await sleep(3000);
      return getPolkadotAccountList(tryTimes);
    }
    return accounts;
  } catch (error) {
    console.log(error);
  }
}

export function checkPolkadotjs() {
  if (
    typeof window.injectedWeb3 !== "undefined" &&
    window.injectedWeb3["polkadot-js"]
  ) {
    return true;
  } else {
    return false;
  }
}

// 创建签名函数
export async function signString(message, account) {
  // 使用 Polkadot.js 扩展进行签名
  const injector = await web3FromSource(account.meta.source);
  const signRaw = injector?.signer?.signRaw;

  if (!!signRaw) {
    // after making sure that signRaw is defined
    // we can use it to sign our message
    const { signature } = await signRaw({
      address: account.address,
      data: stringToHex(message),
      type: "bytes",
    });
    return signature;
  }
}

export function encodeDeeperChain(address) {
  return encodeAddress(hexToU8a(address));
}
export function decodeDeeperChain(address) {
  return u8aToHex(decodeAddress(address));
}

export async function getDeeperChainBalance(deeperChain) {
  const api = await getApi();
  const balance = await api.query.system.account(deeperChain);
  return balance.toJSON() || { balance: 0 };
}

export async function getImOnline(deeperChain) {
  const diffBlock = 17280;
  const api = await getApi();
  let lastBlock = await api.query.system.number();
  lastBlock = lastBlock.toJSON();
  let addressBlock = await api.query.deeperNode.imOnline(deeperChain);
  addressBlock = addressBlock.toJSON();
  console.log(lastBlock, addressBlock);
  if (lastBlock - addressBlock > diffBlock) {
    return false;
  }
  return true;
}

export async function getDPRBalance(deeperChain) {
  const api = await getApi();
  const balance = await api.query.system.account(deeperChain);
  return balance.toJSON();
}

function getDispatchError(dispatchError) {
  let message = dispatchError.type;

  if (dispatchError.isModule) {
    try {
      const mod = dispatchError.asModule;
      const error = dispatchError.registry.findMetaError(mod);

      message = `${error.section}.${error.name}`;
    } catch (error) {
      // swallow
    }
  } else if (dispatchError.isToken) {
    message = `${dispatchError.type}.${dispatchError.asToken.type}`;
  }

  return message;
}

async function send(setTx) {
  const api = await getApi();
  return new Promise(async (resolve, reject) => {
    try {
      let unsub = await setTx.send(({ status, events }) => {
        let hash = "";

        if (status.isFinalized || status.isInBlock) {
          events
            .filter(({ event: { section } }) => section === "system")
            .forEach(
              ({
                event: {
                  method,
                  data: [result],
                },
              }) => {
                if (result.isError) {
                  const error = result.asError;
                  if (error.isModule) {
                    // for module errors, we have the section indexed, lookup
                    const decoded = api.registry.findMetaError(error.asModule);
                    const { docs, name, section } = decoded;
                    console.error(`${section}.${name}: ${docs.join(" ")}`);
                    reject(`${section}.${name}: ${docs.join(" ")}`);
                  } else {
                    // Other, CannotLookup, BadOrigin, no extra info
                    console.error(error.toString());
                    reject(error.toString());
                  }
                }
                if (method === "ExtrinsicFailed") {
                  // let dispatchError = getDispatchError(data.dispatchError);
                  // console.log(dispatchError);
                  reject({ message: "ExtrinsicFailed" });
                } else if (method === "ExtrinsicSuccess") {
                }
              }
            );
        }

        if (status.isFinalized) {
          hash = status.asFinalized.toString() + "-" + setTx.hash.toHex();
          resolve(hash);
          unsub();
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}
