import React from "react";
import DPRImg from "assets/imgs/logo-sm.png";
import styles from "./AddDPRBtn.module.scss";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

export default function AddDPRBtn() {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => {
        window.ethereum
          .request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                address: "0xA0A2eE912CAF7921eaAbC866c6ef6FEc8f7E90A4", // 要添加的代币合约地址
                symbol: "DPR", // 代币符号
                decimals: 18, // 代币小数位数
                image: "https://www.deeper.network/new-logo-3.png", // 代币图标 URL
              },
            },
          })
          .catch((e) => {
            console.log(e);
          });
      }}
      className={styles["container"]}
    >
      <Button className={styles["btn-box"]}>
        <img src={DPRImg} alt="" />
        <div>{t("Add to MetaMask")}</div>
      </Button>
    </div>
  );
}
