import React from "react";
import { useRequest } from "ahooks";

import { Table, Tag } from "antd";
import { encodeDeeperChain } from "w3/polkadot";
import moment from "moment";
import styles from "./BridgeRecord.module.scss";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { BridgeRecordItemDto } from "types/types";
import { getBridgeRecord } from "apis/bridge";

export default function BridgeRecord() {
  const { data: list = [], loading: listLoading } = useRequest(
    getBridgeRecord,
    {
      onSuccess(res) {
        console.log(res);
      },
    }
  );

  const columns = [
    {
      title: "time",
      dataIndex: "createdAt",
      key: "createdAt",
      render(time: string) {
        return moment(time).format();
      },
    },
    {
      title: "hash",
      dataIndex: "_id",
      key: "_id",
      className: styles["break-word"],
      width: 200,
    },
    {
      title: "direction",
      dataIndex: "direction",
      key: "direction",
      render(direction: string) {
        if (direction === "1") {
          return <Tag color="processing">DeeperChain to BSC</Tag>;
        }
        if (direction === "2") {
          return <Tag color="warning">BSC to DeeperChain</Tag>;
        }
      },
    },
    {
      title: "from",
      dataIndex: "_id",
      key: "_id",
      className: styles["break-word"],
      width: 200,
      render(_: any, item: BridgeRecordItemDto) {
        if (item.direction === "1") {
          return encodeDeeperChain(item.substrateSender);
        } else {
          return item.sender;
        }
      },
    },
    {
      title: "to",
      dataIndex: "_id",
      key: "_id",
      className: styles["break-word"],
      width: 200,
      render(_: any, item: BridgeRecordItemDto) {
        if (item.direction === "1") {
          return item.recipient;
        } else {
          return encodeDeeperChain(item.recipient);
        }
      },
    },
    {
      title: "amount",
      dataIndex: "amount",
      key: "amount",
      render(amount: string) {
        return Number((Number(amount) / 1e18).toFixed(5));
      },
    },
    {
      title: "send",
      dataIndex: "send",
      key: "send",
      render(send: boolean) {
        if (send) {
          return (
            <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />
          );
        }
        return (
          <CloseCircleOutlined style={{ color: "orangered", fontSize: 20 }} />
        );
      },
    },

    {
      title: "errorMessage",
      dataIndex: "errorMessage",
      key: "errorMessage",
      width: 200,
      render(errorMessage: string) {
        if (errorMessage) {
          return (
            <Tag
              style={{
                wordBreak: "break-all",
                display: "inline-block",
                whiteSpace: "normal",
              }}
              color="error"
            >
              {errorMessage}
            </Tag>
          );
        }
      },
    },
    {
      title: "withdraw",
      dataIndex: "withdraw",
      key: "withdraw",
      render(withdraw: boolean, item: BridgeRecordItemDto) {
        if (item.direction === "2" || item.errorMessage) {
          return;
        }
        if (withdraw) {
          return (
            <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />
          );
        }
        return (
          <CloseCircleOutlined style={{ color: "orangered", fontSize: 20 }} />
        );
      },
    },
    {
      title: "done",
      dataIndex: "done",
      key: "done",
      render(done: boolean) {
        if (done) {
          return (
            <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />
          );
        }
        return (
          <CloseCircleOutlined style={{ color: "orangered", fontSize: 20 }} />
        );
      },
    },
    {
      title: "removeHash",
      dataIndex: "removeHash",
      key: "removeHash",
      className: styles["break-word"],
      width: 200,
    },
    {
      title: "bridgeSign",
      dataIndex: "bridgeSign",
      key: "bridgeSign",
      className: styles["break-word"],
      width: 200,
    },
    {
      title: "limit",
      dataIndex: "limit",
      key: "limit",
      render(limit: any) {
        if (!limit) {
          return;
        }
        return (
          <div style={{ fontSize: 12 }}>
            <div>todayTotal: {limit.todayTotal}</div>
            <div>
              todayDeeperChain:{" "}
              {Number((limit.todayDeeperChain / 1e18).toFixed(5))}
            </div>
            <div>
              todayErc20: {Number((limit.todayErc20 / 1e18).toFixed(5))}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Table<BridgeRecordItemDto>
      rowKey="_id"
      dataSource={list}
      columns={columns}
    />
  );
}
