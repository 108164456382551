import axios from "axios";
import { message } from "antd";
import i18n from "../i18n";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://api-device.deeper.network/bridgeAdmin"
      : "http://127.0.0.1:7001/bridgeAdmin",
});
// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 从某处获取你的token，例如localStorage
    const token = window.localStorage.getItem("adminToken");
    // 如果token存在，则添加到请求头中
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

export default function fetch(config: any) {
  return instance(config)
    .then((res) => res.data)
    .catch((e) => {
      console.log(e);

      message.error(i18n.t(`Res.${e.response.data}`));
      if (e.response.data === "notAuthenticated") {
        // setTimeout(() => {
        //   window.location.href = "./login";
        //   window.localStorage.removeItem("adminToken");
        // }, 3000);
      }
      return Promise.reject(e);
    });
}
