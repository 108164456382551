import styles from "./Bridge.module.scss";
import { useTranslation } from "react-i18next";
export default function RuleBox({ direction = "1" }: { direction: string }) {
  const { t } = useTranslation();
  return direction === "1" ? (
    <div className={styles["rule-box"]}>
      <h3>{t("DPR <-> BSC Cross-Chain Bridge Rules")}</h3>
      <p>
        1.{" "}
        {t(
          "The burn mechanism does not apply when bridging from BSC to Deeper Chain. The cross-chain transfer will be processed immediately."
        )}
      </p>
      <p>
        2.{" "}
        {t(
          `Please select a burn percentage before transacting on the Deeper Chain to BSC cross-chain transfer. The default is 0% and the maximum is 10,000%. The minimum change is 0.1%. The burned DPR does not come from the transferred balance. You must have sufficient DPR in your Deeper wallet. `
        )}
      </p>
      <p>
        3.{" "}
        {t(
          `The burn percent set by the user will affect future cross-chain transactions. If the burn percent is not modified, it will remain in effect until the next transaction. `
        )}
      </p>
      <p>
        4.{" "}
        {t(
          `The cross-chain bridge has a cycle of 12 hours, with a cross-chain quota of 600,000 DPR per cycle. The maximum amount that can be requested per address is 10,000 DPR, and the minimum is 1,000 DPR. Users can apply multiple times in a cycle. The transaction is executed at the end of each cycle. `
        )}
      </p>
      <p>
        5.{" "}
        {t(
          `The cross-chain bridge processes cross-chain applications once per cycle. After the cycle ends, the transactions will be sorted by burn percentage from highest to lowest then the transactions with the highest burn rates will be prioritized. Users will not lose DPR if their transaction doesn't go through.`
        )}
      </p>
      <p>
        6.{" "}
        {t(
          `For cross-chain transactions with the same burn percentage, transactions with smaller amounts will be prioritized. If the transaction amounts are also the same, and total transactions have exceeded the current cycle limit, then these transactions will be processed randomly until the limit is reached.`
        )}
      </p>
      <p>
        7.{" "}
        {t(
          `The system will count the total burn amount each cycle, and the DPR will be uniformly burned and announced every month.`
        )}
      </p>
      <p>
        {t("Example")} 1: <br />
        {t(
          "User A: cross-chain transaction of 10,000 DPR with a burn percentage of 10%."
        )}
        <br />
        {t(
          "User B: cross-chain transaction of 5,000 DPR with a burn percentage of 10%."
        )}
        <br />
        {t(
          "User C: cross-chain transaction of 5,000 DPR with a burn percentage of 5%."
        )}
        <br />
        {t(
          `In this example, user B's transaction will be prioritized. User B's transaction total will then be 5,500 DPR, of which 5,000 DPR is the cross-chain transaction amount and 500 DPR will be part of the burn pool.`
        )}
      </p>
      <p>
        {t("Example")} 2: <br />
        {t(
          `User A: cross-chain transaction of 10,000 DPR with a burn percentage of 120%.`
        )}
        <br />
        {t(
          `If User A's transaction is prioritized for the current cycle, then User A will be deducted 22,000 DPR, of which 10,000 DPR is the cross-chain transaction amount and 12,000 DPR will be part of the burn pool. If the user's balance has insufficient DPR to deduct 22,000 DPR, then the transaction will not be executed and leave room for the next transaction.`
        )}
      </p>
    </div>
  ) : (
    <div className={styles["rule-box"]}>
      <h3>{t("DPR <-> BSC Cross-Chain Bridge Rules")}</h3>
      <p>
        1.{" "}
        {t(
          "The burn mechanism does not apply when bridging from BSC to Deeper Chain. The cross-chain transfer will be processed immediately."
        )}
      </p>
    </div>
  );
}
