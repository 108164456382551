import React, { useState, useMemo, useEffect } from "react";

import { Table, DatePicker, Tag, Radio } from "antd";

import { useRequest } from "ahooks";
import { encodeDeeperChain } from "w3/polkadot";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getBridgeRanking } from "apis/bridge";
import { BridgeRecordItemDto } from "types/types";
import styles from "./Ranking.module.scss";
import dayjs from "dayjs";

const PeriodMax = 600000;

export default function BridgeRanking({
  setMinBurnRate,
}: {
  setMinBurnRate: (minBurnRate: number) => void;
}) {
  const { t } = useTranslation();
  const [date, setDate] = useState(() => {
    return dayjs();
  });
  const [dateIndex, setDateIndex] = useState(() => {
    const nowUtc = moment.utc();
    const currentHourUtc = nowUtc.hours();
    return currentHourUtc >= 12 ? 2 : 1;
  });
  const [infoData, setInfoData] = useState<{
    burnRateInt: number;
    rankingTotal: number;
  }>({
    burnRateInt: 0,
    rankingTotal: 0,
  });
  const {
    data: list,
    loading: listLoading,
    run: runGetList,
  } = useRequest(getBridgeRanking, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
  });

  useEffect(() => {
    if (list && list.length) {
      const winList = list.filter((it) => !it.isError);
      if (winList.length === 0) {
        return;
      }
      const burnRateInt = winList[winList.length - 1].burnRateInt;
      setMinBurnRate(burnRateInt);
      const infoData: { burnRateInt: number; rankingTotal: number } = {
        burnRateInt,
        rankingTotal: winList[winList.length - 1].rankingTotal || 0,
      };
      setInfoData(infoData);
    } else {
      setMinBurnRate(0);
      setInfoData({
        burnRateInt: 0,
        rankingTotal: 0,
      });
    }
  }, [list]);

  const batch = useMemo(() => {
    const time = moment.utc(+date);
    if (dateIndex === 1) {
      time.hours(12);
    }
    if (dateIndex === 2) {
      time.hours(24);
    }
    return +moment.utc(time.format("YYYYMMDDHH"), "YYYYMMDDHH");
  }, [date, dateIndex]);

  const startTime = useMemo(() => {
    return moment(moment.utc(batch).subtract(12, "hours").format()).format();
  }, [batch]);
  const endTime = useMemo(() => {
    return moment(moment.utc(batch).format()).format();
  }, [batch]);

  useEffect(() => {
    if (batch) {
      runGetList({ batch });
    }
  }, [batch]);
  const burnTotal = useMemo(() => {
    if (!list) {
      return 0;
    }
    let total = 0;
    list.forEach((it) => {
      if (it.isError) {
        return;
      }
      total += Number((Number(it.burnAmount) / 1e18).toFixed(5));
    });
    return Number(total.toFixed(5));
  }, [list]);

  const columns = [
    {
      title: t("Time"),
      dataIndex: "createdAt",
      key: "createdAt",
      render(createdAt: string) {
        return moment(createdAt).format();
      },
    },
    {
      title: t("From"),
      dataIndex: "from",
      key: "from",
      render(_: any, item: BridgeRecordItemDto) {
        const data = encodeDeeperChain(item.substrateSender);
        return `${data.slice(0, 5)}...${data.slice(-5)}`;
      },
    },
    {
      title: t("To"),
      dataIndex: "to",
      key: "to",
      render(_: any, item: BridgeRecordItemDto) {
        const data = item.recipient;
        return `${data.slice(0, 5)}...${data.slice(-5)}`;
      },
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render(amount: string, item: BridgeRecordItemDto) {
        return (
          <div style={{ whiteSpace: "nowrap", fontSize: 12 }}>
            <div>
              {t("Receive Amount")}:{" "}
              {Number((Number(item.contractAmount) / 1e18).toFixed(5))} DPR
            </div>
            <div>
              {t("Burn Amount")}:{" "}
              {Number((Number(item.burnAmount) / 1e18).toFixed(5))} DPR
            </div>
            <div>
              {t("Total Amount")}:{" "}
              {Number((Number(item.amount) / 1e18).toFixed(5))} DPR
            </div>
          </div>
        );
      },
    },
    {
      title: t("Burn Percentage"),
      dataIndex: "burnRateInt",
      key: "burnRateInt",
      render(burnRateInt: number) {
        return <div style={{ whiteSpace: "nowrap" }}>{burnRateInt / 10} %</div>;
      },
    },
    {
      title: t("Occupied amount"),
      dataIndex: "rankingTotal",
      key: "rankingTotal",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render(_: any, item: BridgeRecordItemDto) {
        return (
          <div>
            {item.win && (
              <div>
                <Tag color="success">{t("Win")}</Tag>
              </div>
            )}
            {item.ballot && (
              <div>
                <Tag color="warning">{t("Draw lots")}</Tag>
              </div>
            )}
            {item.isError && (
              <div style={{ margin: "5px 0" }}>
                <Tag
                  color="error"
                  style={{ maxWidth: 100, whiteSpace: "normal" }}
                >
                  {item.errorMessage}
                </Tag>
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ maxWidth: 1200 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
        }}
      >
        <DatePicker
          disabledDate={(currentDate) => {
            let start = new Date("2024-01-11").getTime();
            let end = Date.now() + 3600 * 1000 * 24;
            return +currentDate < start || +currentDate > end;
          }}
          value={date}
          onChange={(val) => {
            if (val) {
              setDate(val);
            }
          }}
        />
        <Radio.Group
          options={[
            { label: "1", value: 1 },
            { label: "2", value: 2 },
          ]}
          onChange={(e) => {
            setDateIndex(e.target.value);
          }}
          value={dateIndex}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
      <div className={styles["header-box"]}>
        <h3 style={{ textAlign: "center" }}>
          <div>{startTime}</div> - <div>{endTime}</div>
        </h3>
        <div>
          <div>{t("Minimum Burn Percentage")} </div>
          <div className={styles["value-box"]}>
            {infoData.burnRateInt ? infoData.burnRateInt / 10 : 0} %{" "}
          </div>
        </div>
        <div>
          <div>{t("Total Win Amount")} </div>
          <div className={styles["value-box"]}>
            {infoData.rankingTotal || 0}{" "}
          </div>
        </div>
        <div>
          <div>{t("Total Burn Amount")} </div>
          <div className={styles["value-box"]}>{burnTotal} </div>
        </div>
      </div>
      <Table<BridgeRecordItemDto>
        size="small"
        rowKey="_id"
        dataSource={list}
        loading={listLoading}
        columns={columns}
      />
    </div>
  );
}
