const { useState } = require("react");

function useAuth(isAdmin: boolean) {
  const [token, setToken] = useState(() => {
    const storeToken = window.localStorage.getItem(
      isAdmin ? "adminToken" : "token"
    );
    if (storeToken) {
      return storeToken;
    }
    return null;
  });

  return {
    token,
  };
}

export default useAuth;
