import { useState, useRef, useEffect, useMemo } from "react";
import { useRequest } from "ahooks";
import {
  getBurnRate as getBurnRateFetch,
  setBurnRate as setBurnRateFetch,
} from "apis/bridge";
import { signMessage } from "w3/eth";
import { message } from "antd";
export function useBurnRate({ modal, t }: { modal: any; t: any }) {
  const [burnRate, setBurnRate] = useState("0");
  const [changeBurnRateOpen, setChangeBurnRateOpen] = useState(false);
  const burnRateInputRef = useRef<any>(null);
  const {
    run: runGetBurnRate,
    refresh: refreshBurnRate,
    loading: getBurnRateLoading,
  } = useRequest(getBurnRateFetch, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      if (!res) {
        return setBurnRate("0");
      }
      return setBurnRate(res);
    },
  });
  const { run: runSetBurnRate } = useRequest(
    async (address: string, burnRateStr: string) => {
      const burnRateNum = Number(burnRateStr);
      console.log(burnRateNum);
      if (burnRateNum.toString() === "NaN") {
        return;
      }
      const signature = await signMessage(address, burnRateNum);
      console.log(signature);
      return setBurnRateFetch(address, burnRateNum, signature);
    },
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        refreshBurnRate();
        setChangeBurnRateOpen(false);
      },
      onError(e) {
        message.error(e.message);
      },
    }
  );

  const onSetBurnRate = async (address: string, burnRateStr: string) => {
    modal.confirm({
      content: t(
        "Are you sure you want to modify the burn percentage? Modifying the burn percentage will affect all uncollected withdrawal requests."
      ),
      onOk: () => {
        runSetBurnRate(address, burnRateStr);
      },
      onCancel: () => {
        setChangeBurnRateOpen(false);
        refreshBurnRate();
      },
    });
  };

  useEffect(() => {
    if (changeBurnRateOpen) {
      burnRateInputRef.current!.focus({
        cursor: "end",
      });
    }
  }, [changeBurnRateOpen]);

  return {
    burnRate,
    setBurnRate,
    changeBurnRateOpen,
    setChangeBurnRateOpen,
    runGetBurnRate,
    onSetBurnRate,
    getBurnRateLoading,
    burnRateInputRef,
  };
}
