const BigNumber = require("bignumber.js");
const Web3 = require("web3");
const DPRToken = require("./DPR/index");
const DPRBridgeToken = require("./DPRBridge/index");

function getWeb3(provider) {
  if (provider) {
    return new Web3(provider);
  }
  if (window.ethereum) {
    return new Web3(window.ethereum);
  }
  return false;
}

export function getNetworkId() {
  return window.ethereum.chainId / 1;
}

function getTokenContract(Token, provider, _networkId) {
  console.log(Token, provider, _networkId);
  const web3 = getWeb3(provider);
  const networkId = _networkId || getNetworkId();
  return new web3.eth.Contract(Token[networkId].abi, Token[networkId].address);
}

function getTokenData(Token) {
  return Token[getNetworkId()];
}

async function checkEnable() {
  if (!window.ethereum) {
    throw "Ethereum Disable";
  }
  return window.ethereum;
}

async function sendTx(tx, from) {
  const web3 = getWeb3();
  const gasPrice = await web3.eth.getGasPrice();
  const gas = await tx.estimateGas({ from });
  return tx
    .send({
      from,
      gasPrice,
      gas: parseInt(gas * 2),
    })
    .on("transactionHash", function (hash) {
      console.log("hash");
      console.log(hash);
    })
    .on("confirmation", function (confirmationNumber, receipt) {
      console.log("confirmation");
      console.log(confirmationNumber, receipt);
    })
    .on("receipt", function (receipt) {
      console.log("receipt");
      console.log(receipt);
    })
    .on("error", function (error, receipt) {
      // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
      console.log("error");
      console.log(error, receipt);
    });
}

export async function switchBSC() {
  // 请求用户授权访问其 MetaMask 账户
  await window.ethereum.enable();

  // 获取当前的网络 ID
  const networkId = getNetworkId();
  // 如果当前网络不是 BSC 网络（网络 ID 为 56），则切换网络
  if (Number(networkId) !== 56) {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x38", // BSC 网络的 Chain ID
          chainName: "Smart Chain",
          nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://bsc-dataseed.binance.org/"], // BSC 节点的 URL
          blockExplorerUrls: ["https://bscscan.com/"], // 区块浏览器的 URL
        },
      ],
    });
  }
}

export async function switchETH() {
  // 请求用户授权访问其 MetaMask 账户
  await window.ethereum.enable();

  // 获取当前的网络 ID
  const networkId = getNetworkId();

  // 如果当前网络不是以太坊主网（网络 ID 为 1），则切换网络
  if (Number(networkId) !== 1) {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }], // 以太坊主网的 Chain ID
    });
  }
}

export async function getCurrentInfo() {
  // 请求用户授权访问其 MetaMask 账户
  await window.ethereum.enable();
  // 读取当前钱包地址
  const accounts = await getWeb3().eth.getAccounts();
  const currentWallet = accounts[0];
  // 读取当前网络信息
  const networkId = getNetworkId();
  return {
    account: currentWallet,
    networkId: Number(networkId),
  };
}

export function checkMetamask() {
  if (typeof window.ethereum !== "undefined") {
    return true;
  } else {
    return false;
  }
}

export async function getBscBalance(address) {
  const balance = await getTokenContract(DPRToken)
    .methods.balanceOf(address)
    .call();
  return balance;
}

export async function deeperChainToEth(deeperChain, eth, amount, sig) {
  console.log(deeperChain, eth, amount, sig);
  await approveBridge(eth);

  const result = await sendTx(
    getTokenContract(DPRBridgeToken).methods.withdrawTransfer(
      deeperChain,
      eth,
      amount,
      sig
    ),
    eth
  );
  return result;
}

export async function confirmWithdraw(messageId, bridgeSign, eth) {
  await approveBridge(eth);
  const result = await sendTx(
    getTokenContract(DPRBridgeToken).methods.confirmWithdrawTransfer(
      messageId,
      bridgeSign
    ),
    eth
  );

  return result;
}

export async function ethToDeeperChain(deeperChain, eth, amount) {
  await approveBridge(eth);
  const result = await sendTx(
    getTokenContract(DPRBridgeToken).methods.setTransfer(amount, deeperChain),
    eth
  );

  return result;
}

export async function approveBridge(from) {
  const allowance = await getTokenContract(DPRToken)
    .methods.allowance(from, getTokenData(DPRBridgeToken).address)
    .call();
  console.log(allowance);
  if (Number(allowance) >= 1e24) {
    return;
  }

  return sendTx(
    getTokenContract(DPRToken).methods.approve(
      getTokenData(DPRBridgeToken).address,
      new BigNumber(1e29).toFixed()
    ),
    from
  );
}

// 签名字符串
export async function signMessage(account, message) {
  const web3 = getWeb3();
  const signature = await web3.eth.personal.sign(String(message), account);
  console.log("Signed message:", signature);
  return signature;
}
